import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-16.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import aclub1 from "../../assets/images/sp/16/aclub-1.jpg";
import aclub2 from "../../assets/images/sp/16/aclub-2.jpg";
import aclub3 from "../../assets/images/sp/16/aclub-3.jpg";
import aclub4 from "../../assets/images/sp/16/aclub-4.jpg";
import aclub5 from "../../assets/images/sp/16/aclub-5.jpg";
import aclub6 from "../../assets/images/sp/16/aclub-6.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`A-Club Savosa | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 16</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://a-club.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="mt-3">Sito ufficiale</Button>
                    </a><br/>
                    <a href="https://www.facebook.com/aclub.lugano" target="_blank">
                        <Button icon={<LinkOutlined/>} className="my-3">Pagina Facebook</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24}} lg={{span: 24}} className="text-justify mb-4">
                                <h1>A-CLUB Fitness & Wellness</h1>
                                <h3>Scopri l’eccellenza dei servizi del Centro Fitness & Wellness più innovativo del
                                    Canton Ticino.</h3>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}} className="text-justify mb-4">
                                <p>A-CLUB Fitness & Wellness situato a Savosa, alle porte da Lugano e facilmente
                                    raggiungibile dalle diverse zone della città rappresenta da anni un punto di
                                    riferimento per coloro che amano tenersi in forma e non lasciare nulla al caso. Il
                                    Club infatti si contraddistingue per l’altissimo livello di professionalità e
                                    preparazione del personale nelle diverse aree di servizio: un team di oltre 60
                                    professionisti accuratamente selezionati ed orientati alla “customer care” e per
                                    l’elevato standard degli ambienti studiati e pensati per garantire al cliente
                                    sicurezza e massimo comfort.</p>
                                <p>Il marchio A-CLUB è noto per offrire numerosi servizi di alta qualità, facilmente
                                    accessibili ed adatti alle diverse tipologie di persone e fasce di età. Grazie
                                    all’esperienza pluridecennale maturata nel settore del fitness, delle attività
                                    legate all’acqua, dello sport di competizione, del benessere, del beauty e della
                                    ristorazione il Club è in grado di offrire a tutti i clienti un servizio unico e
                                    personalizzato al fine di vivere un’esperienza polisensoriale all’interno della
                                    struttura e raggiungere gli obiettivi sportivi e di benessere desiderati.</p>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}} className="text-justify mb-4">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={aclub1} alt="A-Club"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={aclub2} alt="A-Club"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={aclub3} alt="A-Club"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 24}} className="text-justify mb-4">
                                <p>
                                    In tutta l’esposizione, oltre 5.000 mq di superficie sarà possibile vedere
                                    ambientate soluzioni luce straordinarie che renderanno magici i vostri ambienti.
                                    E’ inoltre possibile avvalersi della consulenza personalizzata di esperti nel campo
                                    della progettazione che vi illustreranno programmi completi soluzione per migliore e
                                    valorizzare i vostri ambienti.</p>
                                <p>Il Club offre ai propri soci la possibilità di accedere ad una struttura moderna e
                                    curata nei dettagli, 6’000mq pensati per gli amanti dello sport e per coloro che
                                    desiderano prendersi cura del proprio fisico.
                                    Un’ampia sala attrezzi con tutte le migliori attrezzature presenti sul mercato, 4
                                    sale corsi che propongo oltre 100 ore di corsi fitness alla settimana, uno spazio
                                    acqua unico con 3 piscine: una vasca da 25 metri con 8 corsie, una vasca da 16 metri
                                    per l’acqua fitness (40 ore di corsi la settimana) e i corsi di nuoto e una vasca
                                    ludica con giochi d’acqua.
                                    Fiore all’occhiello della struttura l’esclusiva Area Wellness con SPA e BeautyLAB e
                                    il Ristorante interno a disposizione sia dei soci del Club che degli ospiti esterni,
                                    che offre anche servizi catering a domicilio.</p>
                                <h3>Servizi e Formule di Abbonamento</h3>
                                <p>Al fine di soddisfare al meglio le esigenze ed offrire un servizio esclusivo alla
                                    clientela il Club propone oltre 40 formule di abbonamento personalizzabili per
                                    fascia oraria e tipologia di prodotto: abbonamenti solo per il fitness, oppure solo
                                    per piscina, abbonamenti completi con fitness e piscina e la possibilità di
                                    includere l’area benessere (SPA).
                                    Il cliente può decidere quale percorso intraprendere: se fitness o acqua, oppure
                                    entrambe ed eventualmente integrare nel tempo (entro 3 mesi dalla sottoscrizione)
                                    ulteriori servizi.</p>
                                <h3>Allenarsi e raggiungere gli obiettivi con i Personal Trainer A-CLUB</h3>
                                <p>Per soddisfare le esigenze della moderna clientela che spesso ha poco tempo a
                                    disposizione, poca voglia di allenarsi e troppi impegni durante la giornata, A-CLUB
                                    propone un esclusivo servizio Personal Trainer, eventualmente per chi lo desidera
                                    anche a domicilio nella sua versione A-CLUB HOME FITNESS.
                                    La metodologia di lavoro, l’esperienza e la preparazione dei Trainers A-CLUB
                                    permettono di fare la differenza ed aiutare ogni singolo cliente a capire quali sono
                                    i suoi punti deboli e a trasformarli in punti di forza. La metodologia di lavoro
                                    sviluppata dai Personal Trainer A-CLUB accompagna le persone, passo dopo passo, in
                                    un percorso di riequilibrio della forma fisica e di raggiungimento del
                                    benessere.</p>
                                <h3>3 modi per accedere al servizio Personal Trainer A-CLUB</h3>
                                <p>Il servizio Personal Trainer A-CLUB è stato pensato per soddisfare appieno le diverse
                                    esigenze del cliente e pertanto può essere acquistato in 3 diverse formule: la
                                    classica formula one to one, la soluzione dell’allenamento periodico e il personal
                                    trainer di gruppo.</p>
                                <h6 className="text-uppercase text-primary fw-bold">Servizio One To One</h6>
                                <p>Pensato per coloro che desiderano effettuare le sedute di allenamento sempre con un
                                    Personal Trainer, acquistando pacchetti da dieci, venti, trenta sedute.</p>
                                <h6 className="text-uppercase text-primary fw-bold">Allenamento Periodico</h6>
                                <p>Il Personal Trainer imposta il programma di allenamento del cliente sulla base di
                                    micro o macro cicli a seconda degli obiettivi e segue il cliente a scadenza regolare
                                    per monitorare i risultati e valutare gli step di crescita con riferimento agli
                                    obiettivi finali.</p>
                                <h6 className="text-uppercase text-primary fw-bold">Personal Trainer di Gruppo</h6>
                                <p>Pensato per coloro che desiderano allenarsi assieme ad un gruppo di amici/famigliari.
                                    Si tratta di minigruppi di lavoro composti da 4/6 persone.</p>
                                <h3>L’Area Beauty & SPA a complemento delle sedute di allenamento</h3>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}} className="text-justify mb-4">
                                <p>Il sevizio Beauty & SPA rappresentano il fiore all’occhiello del Club, un’area
                                    esclusiva, dedicata agli amanti del relax e a coloro che desiderano farsi
                                    coccolare.
                                    Per accedere al servizio occorre acquistare una formula di abbonamento che
                                    include
                                    l’area termale con sauna, bio sauna, bagno romano, idromassaggio, docce
                                    emozionali e
                                    area relax, oppure acquistare una tantum una singola entrata.</p>
                                <p>I trainers della sala attrezzi, oltre che a sviluppare il programma di
                                    allenamento,
                                    consigliano al cliente quale percorso relax seguire a completamento della seduta
                                    di
                                    allenamento.
                                    Per ottenere il massimo risultato dalle sedute di allenamento l’Area Beauty del
                                    Club, oltre ai trattamenti estetici tradizionali, offre un’ampia gamma di
                                    trattamenti specifici per combattere gli inestetismi di adipe e cellulite.</p>
                                <p>Nello sviluppo del programma di allenamento il cliente potrà valutare con il
                                    trainer
                                    il trattamento estetico (assolutamente non invasivo) ideale per completare il
                                    lavoro
                                    in palestra e raggiungere più velocemente i risultati desiderati.
                                    Prima di iniziare ogni tipo di percorso al verrà eseguito un check up della
                                    pelle
                                    con l’ausilio di macchinari specifici.</p>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}} className="text-justify mb-4">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={aclub4} alt="A-Club"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={aclub5} alt="A-Club"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={aclub6} alt="A-Club"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}} className="text-justify mb-4">
                                <h3>Come entrare nel Mondo A-Club?</h3>
                                <p>Per coloro che desiderano approfondire la conoscenza dei numerosi servizi offerti
                                    dal
                                    Club e visitare a struttura è possibile fissare direttamente un appuntamento con
                                    uno
                                    dei consulenti ed effettuare un ingresso prova gratuito per i corsi fitness e
                                    fitness in acqua proposti dal palinsesto.
                                    Il Club è aperto durante la settimana dalle 6.30 del mattino alle 23.00, mentre
                                    durante il week end dalle 9.00 alle 20.00.</p>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}} className="text-justify mb-4">
                                <h3>Contatti</h3>
                                <Row gutter={{xs: 16, lg: 32}}>
                                    <Col>
                                        <p>
                                            <b>A-CLUB Fitness & Wellness</b><br/>
                                            Via Campo Sportivo, 6942 Savosa<br/>
                                            Tel. 0041 (0)91 966 13 13<br/>
                                            <a href="mailto:info@a-club.ch">info@a-club.ch</a>
                                        </p>
                                    </Col>
                                    <Col>
                                        <p>
                                           <b>Servizio Ristorante A-CLUB Lounge</b><br/>
                                            Tel. 0041 (0)91 966 44 30<br/>
                                            <a href="mailto:foodservice@a-club.ch">foodservice@a-club.ch</a>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
